<template>
  <div>
    <save
      ref="saveForm"
      :brand="brandName"
      :brand-id="brandId"
      @onSuccess="getItems"
    />
    <h2> {{ brandName }} </h2>
    <div class="mb-2 d-flex justify-content-between">
      <div
        v-if="brandsList.length"
        class=""
      >
        <label for="brand_id">Brandni tanlang</label>
        <b-form-select
          id="brand_id"
          v-model="filterModel.brand_id"
          name="brand_id"
          value-field="value"
          text-field="text"
          placeholder="Brand"
          :options="brandsList"
          @change="onChangeBrand"
        />
      </div>
      <b-button
        v-can="'warehouses.product_categories-store'"
        variant="primary"
        class="mb-2"
        @click="$refs.saveForm.visible = true"
      >
        <feather-icon icon="PlusIcon" />Qo'shish
      </b-button>
    </div>
    <b-overlay :show="loading">
      <good-table-column-search
        :items="items.data"
        :columns="columns"
        :total="items.total"
        :page="page"
        :filter="filterModel"
        @getItems="getItems"
        @add="$refs.saveForm.visible = true"
        @edit="(item) => $refs.saveForm.edit(item)"
        @delete="destroy"
        @onPageChange="(p) => page = p"
      />
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import Save from './save.vue'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch, Save,
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: {},
      tab_id: 1,
      filterModel: {
        per_page: 10,
        brand_id: null,
        relations: 'brand',
      },
      brandName: '',
      // brandsList: [],
    }
  },
  computed: {
    ...mapGetters({ brandsList: 'resource/BRANDS_LIST' }),
    brandId() { return this.$route.params.id },
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: this.$t('Model'),
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Brand'),
          field: 'brand.name',
          filterOptions: {
            enabled: true,
            placeholder: 'Все',
            filterDropdownItems: this.brandsList,
          },
        },
        {
          label: this.$t('Действия'),
          field: 'action',
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.filterModel.brand_id = this.brandId
    this.getItems()
    this.getBrandName()
    this.getBrands({ per_page: 999 })
    // .then(({ data }) => { this.brandsList = data.data.map(el => ({ value: el.id, text: el.name })) })
  },
  methods: {
    ...mapActions({
      getAction: 'resource/indexBrandModels',
      showBrand: 'resource/showBrands',
      getBrands: 'resource/indexBrands',
      destroyAction: 'resource/destroyBrandModels',
    }),
    onChangeBrand(val) {
      this.brandName = this.brandsList.find(b => b.value == val).text
      this.$router.push(`/settings/brand-models/${val}`)
      this.getItems()
    },
    async getItems() {
      this.loading = true
      await this.getAction({
        ...this.filterModel, page: this.page,
      }).then(res => {
        if (res.success) {
          this.items = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    getBrandName() {
      this.showBrand(this.brandId).then(({ data }) => { this.brandName = data.name })
    },
    onChange(id) {
      this.tab_id = id
      this.getItems()
    },
    destroy(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.destroyAction(id).then(res => {
              showToast('success', this.$t('Успешно удалено'))
              this.getItems()
            }).catch(() => {
              showToast('success', this.$t('Успешно удалено'))
            })
          }
        })
    },
  },
}
</script>

<style scoped></style>
