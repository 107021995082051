<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    hide-footer
    centered
    :title="title"
  >
    <!-- {{ brand }}/{{ brandId }} -->
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="valid"
      >
        <div class="row">
          <div class="col-12">
            <p><span class="fs22">{{ brand }}</span>'ga qo'shiladigan mahsulotni kiriting</p>
            <!-- <b-form-group :label="$t('Brendi')" label-for="brand_id">
              <validation-provider #default="{ errors }" name="brand_id" rules="required">
                <b-form-select id="brand_id" v-model="form.brand_id" :state="errors.length > 0 ? false : null"
                  name="brand_id" value-field="value" text-field="text" placeholder="Brand" :options="brandsList"
                  @change="onChangeBrand" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
          </div>
          <div class="col-12">
            <b-form-group
              :label="$t('Mahsulot modeli')"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="form.name"
                  :state="errors.length > 0 ? false : null"
                  name="name"
                  placeholder=""
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>
        <div class="row text-right">
          <b-button
            :disabled="loading"
            variant="primary"
            class="mr-1"
            @click="save"
          >
            Saqlash
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: { ValidationProvider, ValidationObserver },
  props: {
    brandId: {
      type: [Number, String],
      default: () => null,
    },
    brand: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        id: null,
        brand_id: null,
        name: null,
      },
      loading: false,
      selected: null,
      // brandsList: [],
      brandName: '',
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ brandsList: 'resource/BRANDS_LIST' }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.getItems()
    this.title = 'Brand Modelini qo`shish'
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    onChangeBrand(val) {
      this.brandName = this.brandsList.find(b => b.value == val).text
      this.$router.push(`/settings/brand-models/${val}`)
    },
    valid() {
      const valid = this.validationForm()
      console.log(valid)
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.form.brand_id = this.$route.params.id
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        }).finally(() => {
          this.loading = false
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.form.id = item.id
      this.form.name = item.name
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.update(data)
      return this.store(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    async getItems() {
      this.selected = null
      this.loading = true
      this.loading = false
    },
    ...mapActions({
      store: 'resource/storeBrandModels', update: 'resource/updateBrandModels',
    }),
  },
}
</script>

<style scoped></style>
